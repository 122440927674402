// extracted by mini-css-extract-plugin
export const container = "servicesSection-module--container--1NJSx";
export const servicesGrid = "servicesSection-module--servicesGrid--3dRLL";
export const serviceBtn = "servicesSection-module--serviceBtn--T2TNk";
export const serviceBtnMain = "servicesSection-module--serviceBtnMain--A4Qab";
export const serviceBtnDisabled = "servicesSection-module--serviceBtnDisabled--zJQav";
export const serviceImg = "servicesSection-module--serviceImg--1DKuq";
export const colorImg = "servicesSection-module--colorImg--3tC5e";
export const discolorImg = "servicesSection-module--discolorImg--3qMCZ";
export const serviceBtnEffect = "servicesSection-module--serviceBtnEffect--2IdMR";
export const serviceBtnTitle = "servicesSection-module--serviceBtnTitle--1n4xE";
export const serviceBtnTextBG = "servicesSection-module--serviceBtnTextBG--3oLjl";
export const slidedown = "servicesSection-module--slidedown--8oG5v";
export const slideup = "servicesSection-module--slideup--3gh9b";